import React from 'react';
import 'react-multi-carousel/lib/styles.css';
import '../../assets/css/case-study.css';

import challengeOne from '../../assets/images/allergy-amulet/challenge-1.svg';
import challengeTwo from '../../assets/images/allergy-amulet/challenge-2.svg';
import challengeThree from '../../assets/images/allergy-amulet/challenge-3.svg';
import bbby from '../../assets/images/bed-bath-beyond/bed-bath-beyond-feature.webp';
import BedBathBeyondOne from '../../assets/images/bed-bath-beyond/bed-bath-beyond-1-1.webp';
import BedBathBeyondPipeline from '../../assets/images/bed-bath-beyond/bed-bath-beyond-pipeline.webp';
import BedBathBeyondPipelineOne from '../../assets/images/bed-bath-beyond/bbby-pipeline-1.webp';

import BedBathBeyondTwo from '../../assets/images/bed-bath-beyond/bed-bath-beyond-2.webp';
import BedBathBeyondThreeA from '../../assets/images/bed-bath-beyond/bed-bath-beyond-3-aa.webp';
import BedBathBeyondThreeB from '../../assets/images/bed-bath-beyond/bed-bath-beyond-3-bb.webp';
import BedBathBeyondThreeC from '../../assets/images/bed-bath-beyond/bed-bath-beyond-3-cc.webp';
import BedBathBeyondThreeD from '../../assets/images/bed-bath-beyond/bed-bath-beyond-3-dd.webp';
import BedBathBeyondFourthA from '../../assets/images/bed-bath-beyond/bed-bath-beyond-4-a.gif';
import BedBathBeyondFourthB from '../../assets/images/bed-bath-beyond/bed-bath-beyond-4-b.gif';
import BedBathBeyondFifthA from '../../assets/images/bed-bath-beyond/bed-bath-beyond-5-a.webp';
import BedBathBeyondFifthB from '../../assets/images/bed-bath-beyond/bed-bath-beyond-5-b.webp';
import BedBathBeyondFifthC from '../../assets/images/bed-bath-beyond/bed-bath-beyond-5-cc.webp';
import BedBathBeyondFifthD from '../../assets/images/bed-bath-beyond/bed-bath-beyond-5-dd.webp';
import BedBathBeyondSeventhD from '../../assets/images/bed-bath-beyond/bed-bath-beyond-7-d.gif';
import BedBathBeyondSeventhA from '../../assets/images/bed-bath-beyond/bed-bath-beyond-7-a.gif';
import BedBathBeyondSeventhB from '../../assets/images/bed-bath-beyond/bed-bath-beyond-7-b.gif';
import BedBathBeyondSeventhC from '../../assets/images/bed-bath-beyond/bed-bath-beyond-7-c.gif';
import BedBathBeyondSeventhE from '../../assets/images/bed-bath-beyond/bed-bath-beyond-7-e.gif';
import BedBathBeyondEightA from '../../assets/images/bed-bath-beyond/bed-bath-beyond-8-a.webp';
import BedBathBeyondEightB from '../../assets/images/bed-bath-beyond/bed-bath-beyond-8-b.webp';
import BedBathBeyondEightC from '../../assets/images/bed-bath-beyond/bed-bath-beyond-8-c.webp';
import BedBathBeyondEightD from '../../assets/images/bed-bath-beyond/bed-bath-beyond-8-d.webp';
import BedBathBeyondNinthA from '../../assets/images/bed-bath-beyond/bed-bath-beyond-9-a.webp';
import BedBathBeyondNinthB from '../../assets/images/bed-bath-beyond/bed-bath-beyond-9-b.webp';
import BedBathBeyondNinthC from '../../assets/images/bed-bath-beyond/bed-bath-beyond-8-gg.webp';
import BedBathBeyondNinthD from '../../assets/images/bed-bath-beyond/bed-bath-beyond-8-hh.webp';

import BedBathBeyondTenth from '../../assets/images/bed-bath-beyond/bed-bath-beyond-10.gif';

import BedBathBeyondSixth from '../../assets/images/bed-bath-beyond/bed-bath-beyond-6-6.webp';
import quotationBorder from '../../assets/images/bed-bath-beyond/quotation-border.svg';
import tinLogo from '../../assets/images/gamerize/tinLogo.webp';

class BBBY extends React.Component {
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="case-study-section">
          <section className="container jobbox-section-portfolio">
            <div className="row py-md-6 mx-0">
              <div className="col-12 ">
                <div className="title p-0 section-margin">
                  AR Transformation of a Fortune 500 Company’s Ecommerce Mobile App
                </div>
                <div className="subtitle section-margin">
                  Building on immersive technology to allow consumers preview purchases in their own
                  environment
                </div>

                <div className="para-mini section-margin">
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      {' '}
                      <img className="icon " src={challengeOne} alt="" />
                      <div className="subheading ml-2">Client's Business Problem</div>
                    </div>
                    <p className="desc m-0">
                      Build an augmented reality module for their mobile application to help boost
                      sales conversion.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeTwo} alt="" />
                      <div className="subheading ml-2">Output By Tintash</div>
                    </div>

                    <p className="desc">
                      Developed an end-to-end system that allowed the client to create and store 3D
                      models and then pull them into a state-of-the-art mobile AR experience linked
                      to their existing e-commerce system.
                    </p>
                  </div>
                  <div className="mini-card">
                    <div className="d-flex align-items-center">
                      <img className="icon" src={challengeThree} alt="" />
                      <div className="subheading ml-2">Impact For Client</div>
                    </div>
                    <p className="desc">
                      Over 300 AR-enabled products sold within the first week of module’s launch.
                      Increase of 30% in product sales of AR-enabled products from 2018 to 2019.
                    </p>
                  </div>
                </div>
                <div className="col-11 mr-auto ml-auto">
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">What was the client looking for?</div>
                    <p className="description">
                      This client, a US-based Fortune 500 corporation, was looking to follow its
                      competitors into becoming one of the first few companies to enter the realm of
                      augmented reality. They engaged Tintash to build an AR module for their mobile
                      app that would allow a personalized shopping experience to their users by
                      enabling them to visualize products in their own environment.
                    </p>
                    <br />
                    <p className="description">
                      The client also wanted a solution to store, process, and provide 3D product
                      models to their mobile app for visualization. This online portal would offer
                      artists the facility to upload, verify, and edit their 3D models based on our
                      model rendering and conversion pipeline.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin">
                    <div className="subheading-big">How did Tintash deliver?</div>
                    <p className="description">
                      Our team was tasked with building the whole module from scratch. While the
                      client’s US-based team of 3D artists created product models, our job was to
                      take those virtual inventories, ensure production quality standards were met,
                      and visualize them in AR. Our designers would create intuitive experiences
                      which our engineers would then translate into features to engage users in the
                      AR module.
                    </p>
                    <br />
                    <p className="description">
                      Tintash team worked in an iterative manner to deliver the following components
                      that powered the entire AR module:
                    </p>
                    <br />
                    <ul>
                      <li className="description">
                        In-app AR viewer: The AR viewer feature was the interface for users to
                        interact with 3D assets in their own environment. The AR viewer came with
                        embedded options to switch product sizes and colors. The interface also had
                        the shopping cart integrated for a seamless purchasing experience.
                      </li>
                      <li className="description">
                        3D asset management portal: A 3D asset processing, storage, and distribution
                        platform integrated with the in-app AR module. The portal provided an
                        interface for 3D artists to upload assets. The assets would then go through
                        a quality evaluation pipeline which identified the improvements needed to
                        make assets production-ready for the application. After passing quality
                        checks, production-ready assets were then integrated into the application.
                      </li>
                    </ul>
                    <br />
                    <div className="subheading-medium">App Research</div>
                    <p className="description">
                      We began the process with a brief study of existing AR solutions. We also
                      conducted user interviews of the current product. By building a sense of user
                      personas and the overall landscape of the market, we worked alongside the
                      project stakeholders to better strategize features and strengthen the brand’s
                      positioning in the market.
                    </p>
                    <br />
                    <p className="description">
                      Our UX designers explored many AR applications and documented their product
                      flows in detail. We used the competitor analysis to get feedback and approval
                      of the client’s executive team before commencing the design and development
                      phase.
                    </p>
                    <br />
                    <p className="description">
                      As a starting point of our efforts, our team built a visual representation of
                      how the app module would function and behave. The idea served as the
                      foundation for technical analysis to build out a plan of execution.
                    </p>
                    <br />
                    <div className="subheading-medium">Product Design and Development</div>
                    <p className="description">
                      Our work on the product was divided into two different feature phases. The
                      first phase of development was focused on setting up a foundation of the AR
                      module through the visualization of floor items. The second phase of
                      development was focused on innovation to visualize wall items and curtains on
                      windows, something that had not been done with finesse at that point in the AR
                      space. Both approaches followed different timelines and posed a distinct set
                      of challenges.
                    </p>
                  </div>
                  <div
                    className="text-with-image-container section-margin"
                    style={{position: 'relative'}}
                  >
                    <div className="textbox-overlay p-0 col-8">
                      <div className="subheading-small">Phase I: Floor Items</div>
                      <p className="description">
                        During the first phase of development, our main objectives were to
                        standardize the product flow, including animations, haptic feedback, help
                        messages, defining the product’s mechanics and functionality, all the while
                        keeping the design integrity intact.
                      </p>
                      <br />
                      <p className="description">
                        Using our in-depth analysis of competitor apps, we started with creating a
                        system pipeline.
                      </p>
                    </div>

                    <div className="col-12">
                      <img
                        src={BedBathBeyondPipelineOne}
                        alt="bed bath and beyond"
                        style={{width: '100%'}}
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <p className="description">
                      Following this, we worked on the execution concept. Some of the new features
                      we explored at this stage included haptic feedback (vibrations), sound
                      effects, animations of icons and items, guiding messages, and a hovering
                      feature for product information and repositioning. We also included an
                      out-of-bounds scenario as well as offset indicators for finding lost items in
                      the environment.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin col-12 mr-auto ml-auto  ">
                    <div className="image-description text-center mb-2 col-12">
                      Low - Fidelity Wireframes
                    </div>
                    <div className="col-12 mt-3">
                      <img className="w-100" src={BedBathBeyondTwo} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin col-12 mr-auto ml-auto  ">
                    <div className="image-description text-center mb-2 col-12">
                      High - Fidelity Wireframes
                    </div>
                    <div className="col-lg-3 col-6 mt-3">
                      <img
                        className="w-100 "
                        src={BedBathBeyondThreeA}
                        alt="bed bath and beyond"
                        style={{maxHeight: '447px'}}
                      />
                    </div>
                    <div className="col-lg-3 col-6 mt-3">
                      <img
                        className="w-100"
                        src={BedBathBeyondThreeB}
                        alt="bed bath and beyond"
                        style={{maxHeight: '447px'}}
                      />
                    </div>
                    <div className="col-lg-3 col-6 mt-3">
                      <img className="w-100 " src={BedBathBeyondThreeC} alt="bed bath and beyond" />
                    </div>
                    <div className="col-lg-3 col-6 mt-3">
                      <img className="w-100 " src={BedBathBeyondThreeD} alt="bed bath and beyond" />
                    </div>
                  </div>
                  <div className="text-with-image-container section-margin  ">
                    <div
                      className="image-container d-flex flex-wrap justify-content-center pl-3 mb-5"
                      style={{gap: '3rem'}}
                    >
                      <div className="p-0">
                        <img
                          className="bby-sectionImages"
                          src={BedBathBeyondFourthA}
                          alt="audioCardio"
                        />
                      </div>
                      <div className="p-0">
                        <img
                          className="bby-sectionImages"
                          src={BedBathBeyondFourthB}
                          alt="audioCardio"
                        />
                      </div>
                    </div>
                    <p className="description">
                      We next embarked on the build testing phase with these first iteration
                      designs. Although the visual designs were not very sophisticated at this
                      point, our main objective during these sprints was to develop the AR mode such
                      that it was in an eligible format for testing in real-time.
                    </p>
                    <br />
                    <p className="description">
                      After translating our visual design concept into a developed version, we
                      iterated to improve the user interface while also exploring ways to better
                      communicate our product’s journey flow – from point A to point B – to the
                      users. The designs below represent the final version of this first phase of
                      floor items. This time, these were made with a properly defined design file,
                      including assets such as icons, 3D rendered images, text styles, colors, and
                      buttons.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <div className="d-flex flex-wrap justify-content-center col-12">
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondFifthA}
                          alt="bed bath and beyond"
                          style={{maxHeight: '478px'}}
                        />
                        <div className="image-description text-center my-3">Scanning Floor</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondFifthB}
                          alt="bed bath and beyond"
                          style={{maxHeight: '478px'}}
                        />
                        <div className="image-description text-center my-3">Placing Item</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondFifthC}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">Hovering State</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondFifthD}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">Help Button</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <p className="description">
                      Once these designs were translated by our developers, the build was shared
                      with the client for their final approval before being made live.
                    </p>
                  </div>
                  <div className="text-with-image-container section-margin  ">
                    <div className="image-container d-flex flex-wrap justify-content-center ">
                      <div className="col-lg-12 col-12 p-0">
                        <img
                          className="w-100 sub-pic "
                          style={{maxWidth: '100%'}}
                          src={BedBathBeyondSixth}
                          alt="audioCardio"
                        />
                      </div>
                    </div>
                    <div className="subheading-small">Phase II: Wall Items & Curtain Viewer</div>
                    <p className="description">
                      During this phase which lasted four months, we mainly focused on exploring how
                      to place curtains over a window. The concept had been attempted before in an
                      experimental capacity but the challenge was to implement it in AR with
                      finesse.
                    </p>
                    <br />
                    <p className="description">
                      The curtain viewer feature would have offered the client a huge edge over its
                      competitors’ offerings, however, accomplishing this feat posed some
                      challenges:
                    </p>
                    <br />
                    <ul>
                      <li className="description">
                        The biggest technical challenge was to detect a window, identify the
                        placement area of the asset and adjust the asset size dynamically to fit the
                        window. There were no off-the-shelf components available in the industry to
                        leverage and use as a baseline of development. 
                      </li>

                      <li className="description">
                        Since window sizes on a user’s end were a variable, our curtain 3D assets
                        had to be designed in a way that would allow them to fit on a user’s window
                        dynamically. This would add the visual finesse in the experience and would
                        provide users a more accurate representation of how the curtain would look
                        like on their window. This required an innovative approach to 3D modeling of
                        the assets.
                      </li>
                    </ul>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <p className="description">
                      We started by studying competitor apps to analyze their experience flows for
                      wall items. While we found some apps that catered to the general wall items
                      such as paintings, in most cases, the apps would either collapse or their
                      product quality was low. However, there was no app available that catered to
                      curtains.
                    </p>
                    <br />
                    <p className="description">
                      As with floor items in the first phase, we created a system pipeline to map
                      our users’ journey. Our engineers divided the solution into two prototyping
                      phases. The first phase focused on using computer vision to detect a window
                      through the visual feed of the camera. The second phase of prototyping focused
                      on placing a curtain 3D model over the detected area.
                    </p>
                    <br />
                    <p className="description">
                      During the front and back-end development phase, we conducted a lot of
                      experiments to improve our results. While testing our build, we worked on
                      improving the app’s detection ability so that it was able to capture the exact
                      detection point. We also added curtain animations (to open and close them) and
                      SKU switching options.
                    </p>
                  </div>
                  <div className="d-flex flex-wrap justify-content-center section-margin col-12 mr-auto ml-auto  ">
                    <div className="col-lg-2 col-6 mt-3">
                      <img
                        className="w-100"
                        src={BedBathBeyondSeventhA}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-2 col-6 mt-3">
                      <img
                        className="w-100"
                        src={BedBathBeyondSeventhB}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-2 col-6 mt-3">
                      <img
                        className="w-100"
                        src={BedBathBeyondSeventhC}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-2 col-6 mt-3">
                      <img
                        className="w-100"
                        src={BedBathBeyondSeventhD}
                        alt="bed bath and beyond"
                      />
                    </div>
                    <div className="col-lg-2 col-6 mt-3">
                      <img
                        className="w-100"
                        src={BedBathBeyondSeventhE}
                        alt="bed bath and beyond"
                      />
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <p className="description">
                      In the final sprint, we made some changes based on user feedback such as
                      introducing a feature to adjust the window and curtain placement area
                      manually, on top of the algorithm-assisted initial window detection. We also
                      worked on polishing the UI and included action oriented steps to allow for
                      easier navigation through the application.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <div className="d-flex flex-wrap justify-content-center col-12">
                      <div className="image-description text-center my-3 col-12">
                        High - Fidelity Wireframes
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondEightA}
                          alt="bed bath and beyond"
                          style={{maxHeight: '479px'}}
                        />
                        <div className="image-description text-center my-3">Scanning Floor</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondEightB}
                          alt="bed bath and beyond"
                          style={{maxHeight: '479px'}}
                        />
                        <div className="image-description text-center my-3">Detecting Wall</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondEightC}
                          alt="bed bath and beyond"
                          style={{maxHeight: '479px'}}
                        />
                        <div className="image-description text-center my-3">Detecting Window</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondEightD}
                          alt="bed bath and beyond"
                          style={{maxHeight: '479px'}}
                        />
                        <div className="image-description text-center my-3">Window Detected</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondNinthA}
                          alt="bed bath and beyond"
                          style={{maxHeight: '479px'}}
                        />
                        <div className="image-description text-center my-3">
                          Manual Grid Editing
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3 mb-lg-0">
                        <img
                          className="w-100"
                          src={BedBathBeyondNinthB}
                          alt="bed bath and beyond"
                          style={{maxHeight: '479px'}}
                        />
                        <div className="image-description text-center my-3">
                          Downloading Product Item
                        </div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3 mb-lg-0">
                        <img
                          className="w-100"
                          src={BedBathBeyondNinthC}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3">Item Placement</div>
                      </div>
                      <div className="col-lg-3 col-sm-6 my-3">
                        <img
                          className="w-100"
                          src={BedBathBeyondNinthD}
                          alt="bed bath and beyond"
                        />
                        <div className="image-description text-center my-3 mb-lg-0">
                          Curtain Animation
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <p className="description">
                      After this final iteration, the designs went into the development phase to be
                      translated. The build was shared with the client who received it very well and
                      gave the approval for making it live.
                    </p>
                  </div>
                  <div className="col-12 p-0 section-margin  ">
                    <div className="subheading-big">How did the final product look?</div>
                    <p className="description">
                      During the course of this project spread over two phases and lasting multiple
                      years, we implemented AR visualizations for domestic merchandise such
                      furniture, appliances, as well as wall items like mirrors, paintings, and
                      curtains. In this short timeline, the team successfully delivered a product
                      matching the design and experiences of competitor offerings while also
                      pioneering the curtain feature in the AR domain.
                    </p>
                    <br />
                    <p className="description">
                      We also created an online portal for the client’s artists to continually
                      expand the module’s virtual inventories using our dedicated 3D model
                      preparation, conversion and rendering pipeline. The portal uses extremely
                      light 3D models to keep downloading time minimal and app memory in check while
                      at the same time retaining a high texture quality.
                    </p>
                    <br />
                    <p className="description">
                      Since augmented reality is an emerging field, the client wanted a partner that
                      could constantly innovate and push the boundaries of this technology. Tintash
                      delivered just that. Our pioneering work on the curtain development feature is
                      an apt example. Despite the industry lacking any precedent, our team was able
                      to successfully deliver a feature demo within one week.
                    </p>
                    <div className="image-container text-center my-2">
                      <img src={BedBathBeyondTenth} alt="bbby" />
                    </div>
                  </div>
                  {/* <div className="text-with-image-container section-margin  ">
                    <div className="image-container d-flex flex-wrap justify-content-center col-lg-3">
                      <div className="col-lg-12 col-12 p-0">
                        <img
                          className="w-100 bby-sectionImages"
                          src={BedBathBeyondTenth}
                          alt="audioCardio"
                        />
                      </div>
                    </div>
                    <div className="col-lg-9 ">
                      <img src={quotationBorder} alt="Quotation Border" style={{width: '100%'}} />
                      <p className="review-body">
                        Tintash has proven to be a reliable and committed development partner. They
                        strive to deliver high-quality work on time. They are detail-oriented and
                        make a concerted effort to integrate with existing teams. Tintash’s
                        technical skill set is impressive, but their loyalty is most commendable.
                      </p>
                      <br />
                      <p className="description">
                        The goal of offering AR visualization of products was to boost customer
                        engagement and sales conversion. Once the build went live after phase 1, it
                        successfully converted 2000 daily users on average in the first two weeks.
                        In phase 2, we observed over 200 users who made purchases using the AR
                        experience in the first five days.
                      </p>
                      <br />
                      <p className="description">
                        Although small, this conversion rate was significant for two reasons. First,
                        this user influx was organic since the client had not yet started promoting
                        the AR feature via focused marketing. Users who were engaging with this AR
                        feature only discovered it either in the product display page or through the
                        AR icon placed beside the product image. Secondly, only a small proportion
                        of inventories were erstwhile available in the AR mode.
                      </p>
                      <p className="description">
                        As augmented reality makes rapid inroads in every industry and transforms
                        consumer behavior, it has become imperative for leading businesses to
                        embrace immersive technology solutions to remain competitive down the years.
                        The module we developed ideally positioned this Fortune 500 company to
                        capture gains of the AR revolution.
                      </p>
                    </div>
                  </div> */}
                  <div className="text-with-image-container section-margin  ">
                    <img
                      className="col-9 p-0"
                      src={quotationBorder}
                      alt="Quotation Border"
                      style={{width: '100%'}}
                    />
                    <p className="review-body">
                      Tintash has proven to be a reliable and committed development partner. They
                      strive to deliver high-quality work on time. They are detail-oriented and make
                      a concerted effort to integrate with existing teams. Tintash’s technical skill
                      set is impressive, but their loyalty is most commendable.
                    </p>
                    <p className="description section-margin">
                      The goal of offering AR visualization of products was to boost customer
                      engagement and sales conversion. Once the build went live after phase 1, it
                      successfully converted 2000 daily users on average in the first two weeks. In
                      phase 2, we observed over 200 users who made purchases using the AR experience
                      in the first five days.
                    </p>
                    <br />
                    <p className="description">
                      Although small, this conversion rate was significant for two reasons. First,
                      this user influx was organic since the client had not yet started promoting
                      the AR feature via focused marketing. Users who were engaging with this AR
                      feature only discovered it either in the product display page or through the
                      AR icon placed beside the product image. Secondly, only a small proportion of
                      inventories were erstwhile available in the AR mode.
                    </p>
                    <br />

                    <p className="description">
                      As augmented reality makes rapid inroads in every industry and transforms
                      consumer behavior, it has become imperative for leading businesses to embrace
                      immersive technology solutions to remain competitive down the years. The
                      module we developed ideally positioned this Fortune 500 company to capture
                      gains of the AR revolution.
                    </p>
                  </div>
                  <div className="section-margin" />
                </div>
              </div>
            </div>
          </section>

          <section className="container tintash-sign">
            <div className="row">
              <div className="col-12">
                <div className="tin-text"> Created and Developed by</div>
                <div className="tin-img">
                  {' '}
                  <img src={tinLogo} alt="bm" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default BBBY;
